import { Button } from '@/components/ui/button';
import { CardContent, CardFooter } from '@/components/ui/card';
import { formatBTC, formatDate, utcDate } from "@/lib/formatters";
import { RankedBid } from '@/schemas/auction';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';
import BidInput from './bidInput';
import DateSelector from './dateSelector';
import FeeRateSelector from './feeRateSelector';
import { useAccountStore } from '@/stores/accountStore';

interface PlaceBidFormProps {
  bidInputValue: string | number;
  dateInputValue: string;
  datesTaken: string[];
  feeRate: number;
  floor10: number;
  floor16: number;
  floor3: number;
  getMaxBidForDate: (date: string) => number;
  isFormValid: boolean;
  minimumBid: number;
  onBidAmountChange: (amount: string | number) => void;
  onDateChange: (date: string) => void;
  onDecrement: () => void;
  onFeeRateChange: (rate: number) => void;
  onIncrement: () => void;
  onSubmit: any;
  setStep: (step: number) => void;
  step: number;
  top16Amounts: number[];
  userLastBid: RankedBid | null;
}

const PlaceBidForm: React.FC<PlaceBidFormProps> = ({
  bidInputValue,
  dateInputValue,
  datesTaken,
  feeRate,
  floor10,
  floor16,
  floor3,
  getMaxBidForDate,
  isFormValid,
  minimumBid,
  onBidAmountChange,
  onDateChange,
  onDecrement,
  onFeeRateChange,
  onIncrement,
  onSubmit,
  setStep,
  step,
  top16Amounts,
  userLastBid,
}) => {
  const [isDateComplete, setIsDateComplete] = useState(false);
  const [direction, setDirection] = useState(1);
  const inputNum = (typeof bidInputValue === 'string') ? parseFloat(bidInputValue.replace(/,/g, '')) : bidInputValue;
  const validForDate = inputNum >= (minimumBid + 50000) / 100000000;
  const validForCycle = inputNum >= (floor16 + 50000) / 100000000;
  const maxDate = useMemo(() => calculateMaxDate(), []);
  const maxForDate = useMemo(() => getMaxBidForDate(dateInputValue), [dateInputValue, getMaxBidForDate]);
  const isConnected = useAccountStore(state => state.isConnected);

  const handleNext = () => {
    if (step === 1 && isDateComplete) {
      setDirection(1);
      setStep(2);
    } else if (step === 2) {
      setDirection(1);
      setStep(3);
    }
  }

  const handleBack = () => {
    setDirection(-1);
    setStep(step - 1);
  }

  useEffect(() => {
    setIsDateComplete(/^\d{4}-\d{2}-\d{2}$/.test(dateInputValue));
  }, [dateInputValue]);

  return (
    <form className='p-0 w-full' onSubmit={onSubmit}>
      <CardContent className="space-y-6 relative overflow-hidden">
        <AnimatePresence custom={direction} initial={false} mode='popLayout'>
          {step === 1 && (
            <motion.div
              key="step1"
              custom={direction}
              variants={formStepMotionVariants}
              initial="enter"
              animate="center"
              exit="exit"
            >
              <DateSelector
                datesTaken={datesTaken}
                dateInputValue={dateInputValue}
                onDateChange={onDateChange}
                maxDate={maxDate}
              />
            </motion.div>
          )}
          {step === 2 && (
            <motion.div
              key="step2"
              custom={direction}
              variants={formStepMotionVariants}
              initial="enter"
              animate="center"
              exit="exit"
            >
              <BidInput
                bidInputValue={bidInputValue}
                onBidAmountChange={onBidAmountChange}
                onIncrement={onIncrement}
                onDecrement={onDecrement}
                minimumBid={minimumBid}
                maxForDate={maxForDate}
                floor3={floor3}
                floor10={floor10}
                top16Amounts={top16Amounts}
              />
            </motion.div>
          )}
          {step === 3 && (
            <motion.div
              key="step3"
              custom={direction}
              variants={formStepMotionVariants}
              initial="enter"
              animate="center"
              exit="exit"
              className='flex flex-col gap-4'
            >
              {userLastBid && <LastBid lastBid={userLastBid} newBidAmount={inputNum} newBidDate={dateInputValue} />}
              <FeeRateSelector
                feeRate={feeRate}
                onFeeRateChange={onFeeRateChange}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </CardContent>
      <CardFooter className={`px-6 pb-6 pt-0 gap-4  ${step === 1 ? 'justify-end' : 'justify-between'}`}>
        {step > 1 && (
          <Button className='w-1/3' type="button" onClick={handleBack} variant="outline">
            Back
          </Button>
        )}
        {step < 3 ? (
          <Button
            className='w-1/3 '
            key='nextButton'
            type="button"
            onClick={handleNext}
            disabled={(step === 1 && !isDateComplete) || (step === 2 && (!validForDate || !validForCycle))}
          >
            Next
          </Button>
        ) : (
          <Button
            key='submitButton'
            type="submit"
            className={"w-2/3" + `text-base w-full transition-all duration-200 my-button  bg-transparent px-4 hover:bg-transparent`}
            disabled={
              !isFormValid
              || !dateInputValue
              || ((typeof bidInputValue === 'number')
                && bidInputValue * 100000000 < Math.max(maxForDate, floor16) + 50000)
            }
          >
            {isConnected ?  "Place Bid" : "Connect Wallet and Place Bid"}
          </Button>
        )}
      </CardFooter>
    </form>
  );
};


const LastBid = ({ lastBid, newBidAmount, newBidDate }: { lastBid: RankedBid, newBidAmount: number, newBidDate: string }) => {

  return <div className="flex w-full flex-col justify-center gap-4 border rounded-lg p-4 border-amber-400/60">
    <span className="text-lg font-medium">This will replace your previous bid!</span>
    <div className=" text-base text-primary">
      Each wallet can place one bid per auction. This new bid will replace your previous bid.
    </div>
    <div className="grid grid-cols-2 gap-4 text-base text-nowrap justify-evenly w-full">

      <div className="flex text-center flex-col gap-2 p-2 rounded-lg border-yellow-300/40 border-2">
        <div className='font-bold'>Previous Bid</div>
        <div>{formatDate(lastBid.kit_date)}</div>
        <div>{formatBTC(lastBid.amount)} BTC</div>
      </div>
      <div className="flex text-center flex-col gap-2 p-2 rounded-lg">
        <div className='font-bold'>New Bid</div>
        <div>{formatDate(newBidDate)}</div>
        <div>{newBidAmount} BTC</div>
      </div>
    </div>
  </div>
};

const formStepMotionVariants = {
  enter: (direction: number) => ({
    x: direction > 0 ? 100 : -100,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
    transition: {
      x: { type: "spring", stiffness: 300, damping: 30 },
      opacity: { duration: 0.2 }
    }
  },
  exit: (direction: number) => ({
    x: direction > 0 ? -100 : 100,
    opacity: 0,
    transition: {
      x: { type: "spring", stiffness: 300, damping: 30 },
      opacity: { duration: 0.2 }
    }
  }),
};

const calculateMaxDate = () => {
  const date = utcDate();
  date.setDate(date.getDate() - 90);
  return date.toISOString().split('T')[0];
};

export default PlaceBidForm;
