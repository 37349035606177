import { Button } from '@/components/ui/button';
import { Slider } from '@/components/ui/slider';
import { useState } from 'react';

type FeeRateSelectorProps = { feeRate: number; onFeeRateChange: (rate: number) => void; };  

const FeeRateSelector = ({ feeRate, onFeeRateChange }: FeeRateSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="space-y-2">
      <div className=" text-sm mt-2">
        Current Fee Rate:
        <span className='font-bold text-base mx-2'>
          {feeRate}
        </span>
        sats/vbyte
        {!isOpen &&
          <Button
            variant='ghost'
            className='text-muted-foreground cursor-pointer ml-2 italic'
            onClick={() => setIsOpen(true)}>(click to change)
          </Button>
        }
      </div>

      {isOpen && (
        <div
          key='feeRateSelectorSlider'
        >
          <Slider
            min={10}
            max={40}
            step={1}
            value={[feeRate]}
            onValueChange={(value) => onFeeRateChange(value[0])}
            aria-label="Select fee rate"
            className="my-0 py-4"
          />
          <div className="flex justify-between text-xs px-2">
            <span>10 sats/vbyte</span>
            <span>40 sats/vbyte</span>
          </div>
        </div>
      )}
    </div>
  )
};

export default FeeRateSelector;