import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogOverlay, DialogTitle } from '@/components/ui/dialog';
import { formatBTC, formatDate, utcDate } from '@/lib/formatters';
import { isMobile } from '@/lib/isMobile';
import { useAccountStatusQuery, usePlaceBidMutation } from '@/queries/account';
import { useAuctionCycleBidDatesQuery, useCurrentAuctionQuery, useDateTakenQuery } from '@/queries/auction';
import { AccountInfo } from '@/schemas/api';
import { KitDateBidSummary, AuctionDetails, RankedBid } from '@/schemas/auction';
import { useAccountStore } from '@/stores/accountStore';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'sonner';
import { AuctionGrid } from './AuctionGrid';
import PlaceBidForm from './placeBidForm/placeBidForm';
import { Loader2 } from 'lucide-react';
import { useSettingsStore } from '@/stores/settingsStore';


const getUserLastBid = (accountStatus: AccountInfo | null | undefined, auctionCycle: number | null): RankedBid | null => {
  if (!auctionCycle) { return null }
  if (!accountStatus) { return null }
  if (!accountStatus.bids) { return null }
  if (accountStatus.bids.length === 0) { return null }
  if (!auctionCycle) { return null }
  const userBids = accountStatus.bids.filter(bid => bid.auction_cycle === auctionCycle);
  if (userBids.length === 0) { return null }
  const lastBid = userBids.sort((a, b) => b.created > a.created ? 1 : -1)[0];
  return lastBid || null;
}

export const useAuctionDetails = (bidDates: KitDateBidSummary[]) => {

  const calculateFloorThresholds = useCallback((nValues: number[]): Record<string, number> => {
    const sortedBids = [...bidDates].sort((a, b) => {
      const bidDiff = b.top_bid.amount - a.top_bid.amount;
      if (bidDiff === 0) {
        return a.top_bid.created < b.top_bid.created ? -1 : 1;
      }
      return bidDiff;
    });

    return nValues.reduce((acc, n) => {
      acc[`floor${n}`] = sortedBids.length >= n ? sortedBids[n - 1].top_bid.amount : 0;
      return acc;
    }, {} as Record<string, number>);
  }, [bidDates]);

  const { floor3, floor10, floor16 } = useMemo(() => calculateFloorThresholds([3, 10, 16]), [calculateFloorThresholds]);

  const getMaxBidForDate = useCallback((date: string): number => {
    const bidForDate = bidDates.find(bidDate => bidDate.top_bid.kit_date === date);
    return bidForDate ? bidForDate.top_bid.amount : 0;
  }, [bidDates]);

  const calculateMinimumBid = useCallback((date: string): number => {
    return Math.max(floor16, getMaxBidForDate(date));
  }, [floor16, getMaxBidForDate]);

  return {
    floor3,
    floor10,
    floor16,
    getMaxBidForDate,
    calculateMinimumBid,
  };
};

const useBidForm = (initialDate: string, initialBid: string) => {
  const [dateInputValue, setDateInputValue] = useState(initialDate);
  const [bidInputValue, setBidInputValue] = useState(initialBid);
  const [feeRate, setFeeRate] = useState(10);
  const [isFormValid, setIsFormValid] = useState(false);

  const isValidDate = useCallback((dateString: string) => {
    const date = utcDate(dateString);
    return date instanceof Date && !isNaN(date.getTime()) &&
      date >= utcDate('2009-01-09') && date <= utcDate();
  }, []);

  const validateForm = useCallback((date: string, bidAmount: number) => {
    return isValidDate(date) && bidAmount >= 0;
  }, [isValidDate]);

  const handleDateChange = (value: string) => {
    setDateInputValue(value);
    const newval = ((typeof bidInputValue === 'string') ? parseFloat(bidInputValue.replace(/,/g, '')) : bidInputValue);
    setIsFormValid(validateForm(value, newval));
  }

  const handleBidAmountChange = useCallback((value: string | number) => {
    const numericValue = ((typeof value === 'string') ? parseFloat(value.replace(/,/g, '')) : value);
    if (!isNaN(numericValue) && numericValue >= 0) {
      setBidInputValue(value.toString());
      setIsFormValid(validateForm(dateInputValue, numericValue));
    } else {
      setBidInputValue('');
      setIsFormValid(false);
    }
  }, [dateInputValue, validateForm]);

  return {
    dateInputValue,
    bidInputValue,
    feeRate,
    isFormValid,
    handleDateChange,
    handleBidAmountChange,
    setFeeRate
  };
};

export type AuctionTableWithBidFormProps = {
  placeBidMutation: ReturnType<typeof usePlaceBidMutation>;
}

const AuctionTableWithBidForm: React.FC<AuctionTableWithBidFormProps> = ({ placeBidMutation }) => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const {
    ordinalAddress: ordinal_address,
    btcAddress: btc_address,
    btcAddressPubKey: btc_address_pub_key
  } = useAccountStore();
  const network = useAccountStore(state => state.network);
  const isConnected = useAccountStore(state => state.isConnected);
  const openWalletDialog = useSettingsStore(state => state.openWalletDialog);
  const { data: accountStatus } = useAccountStatusQuery();
  const [selectedDate, setSelectedDate] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [dialogKey, setDialogKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [resumeAfterConnect, setResumeAfterConnect] = useState(false);

  const { data: datesTaken } = useDateTakenQuery(network);
  const { data: currentAuctionData } = useCurrentAuctionQuery();
  const currentAuction = (currentAuctionData !== undefined && currentAuctionData !== null)
    ? currentAuctionData as unknown as AuctionDetails
    : null;

  const bidDateQuery = useAuctionCycleBidDatesQuery(currentAuction?.auction_cycle.toString() || '');
  const bidDates = bidDateQuery.data;

  useEffect(() => {
    setIsMobileDevice(isMobile());
  }, []);

  const userLastBid = useMemo(() => getUserLastBid(accountStatus, currentAuction?.auction_cycle || null), [accountStatus, currentAuction?.auction_cycle]);


  const {
    floor3,
    floor10,
    floor16,
    getMaxBidForDate,
    calculateMinimumBid,
  } = useAuctionDetails(bidDates || []);

  const minimumBid = useMemo(() => calculateMinimumBid(selectedDate), [calculateMinimumBid, selectedDate]);
  const {
    dateInputValue,
    bidInputValue,
    feeRate: fee_rate,
    isFormValid,
    handleDateChange,
    handleBidAmountChange,
    setFeeRate
  } = useBidForm(selectedDate, '');



  const handleClose = useCallback(() => {
    setStep(1);
    handleBidAmountChange('');
    handleDateChange('');
    setSelectedDate('');
    setIsOpen(false);
    setDialogKey(prev => prev + 1);
    setLoading(false);
  }, [handleBidAmountChange, handleDateChange]);


  const handleIncrement = useCallback(() => {
    const currentValueSats = (typeof bidInputValue === 'string') ? parseFloat(bidInputValue.replace(/,/g, '')) * 1e8 : bidInputValue * 1e8;
    const newValueSats = currentValueSats + 100000;
    const newValue = (newValueSats / 100000000);

    handleBidAmountChange(newValue);
  }, [bidInputValue, handleBidAmountChange]);

  const handleDecrement = useCallback(() => {
    const currentValueSats = (typeof bidInputValue === 'string') ? parseFloat(bidInputValue.replace(/,/g, '')) * 1e8 : bidInputValue * 1e8;
    const newValueSats = Math.max(currentValueSats - 100000, 0);
    const newValue = (newValueSats / 1e8);

    handleBidAmountChange(newValue);
  }, [bidInputValue, handleBidAmountChange]);

  const handleQuickBid = (date: string, amount: number) => {
    if (isMobileDevice) {
      return;
    }

    if (date === '2009-01-12') {
      return;
    }

    setStep(2);
    setSelectedDate(date);
    handleDateChange(date);
    handleBidAmountChange(amount.toString());
    setIsOpen(true);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!currentAuction) {
      toast.error('Current auction details not found. Please reload the page and try again.');
      return;
    }

    if (!ordinal_address || !btc_address || !btc_address_pub_key) {
      if (network) {
        openWalletDialog();
        setResumeAfterConnect(true);
      }
      return;
    }

    const bidAmountSats = Math.round(parseFloat(bidInputValue.replace(/,/g, '')) * 100000000);

    setLoading(true);
    placeBidMutation.mutate({
      kitDate: dateInputValue,
      feeRate: fee_rate,
      amount: bidAmountSats,
    }, {
      onSuccess: (_bidResponse) => {
        bidDateQuery.refetch();
        if (!resumeAfterConnect) handleClose();
      },
      onError: (_error) => {
        setLoading(false);
        toast.error('An error occurred while placing your bid. Please try again.');
      }
    });
  };

  const handleContinueSubmit = async () => {
    const bidAmountSats = Math.round(parseFloat(bidInputValue.replace(/,/g, '')) * 100000000);
    setLoading(true);
    placeBidMutation.mutate({
      kitDate: dateInputValue,
      feeRate: fee_rate,
      amount: bidAmountSats,
    }, {
      onSuccess: (_bidResponse) => {
        bidDateQuery.refetch();
        handleClose();
        setLoading(false);
      },
      onError: (_error) => {
        setLoading(false);
        toast.error('An error occurred while placing your bid. Please try again.');
      }
    });
  };

  useEffect(() => {
    if (resumeAfterConnect && isConnected) {
      setResumeAfterConnect(false);
      handleContinueSubmit();
    }
  }, [resumeAfterConnect, isConnected]);


  if (!currentAuction) {
    return <div>Loading...</div>;
  }
  return (
    <div className='
        grid grid-cols-subgrid items-center sm:row-start-1
        col-span-full sm:col-span-12
        gap-4
      '>
      <>
        <div className='flex justify-between items-end w-full col-span-full sm:col-span-12 border-b'>

          <h2 className='w-1/2 sm:text-2xl text-nowrap border-0 col-span-full sm:col-span-12'>Bids</h2>
          <Button
            size={'default'}
            className='
            w-1/2 sm:w-1/4
            px-12 rounded-md shadow-sm hover:shadow-inner
            text-lg my-1
            text-zinc-50 border border-muted-foreground/90
            bg-gradient-to-b to-45% hover:to-50%
            from-zinc-300 to-zinc-900/80
            hover:to-zinc-800/80
            '
            onClick={() => {
              handleBidAmountChange(formatBTC(floor16 + 50000));
              setIsOpen(true)
            }}
          >
            Bid
          </Button>
        </div>
        <AuctionGrid
          key={`datagrid`}
          data={bidDates}
          onQuickBid={handleQuickBid}
          calculateMinimumBid={calculateMinimumBid}
        />
      </>

      <Dialog open={isOpen}
        onOpenChange={(open) => {
          if (!open) {
            handleClose();
          }
        }}
      >
        <DialogOverlay className={loading ? "bg-black/80" : ""} />
        <DialogContent
          className={`
                  ${loading ? 'cursor-wait brightness-75' : 'cursor-auto'}
                  fixed max-w-xl mt-16 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[108] w-full  overflow-y-auto p-0 sm:p-0 lg:text-xl bg-transparent border-0`}
          onInteractOutside={() => resumeAfterConnect ? null : handleClose()}
          onEscapeKeyDown={() => handleClose()}
        >
          <div className='bg-background rounded-lg border shadow-lg p-4'>
            <DialogTitle className="text-lg sm:text-xl font-semibold mb-4 w-full">
              {step === 1 && "Select Date"}
              {step === 2 && `Enter Bid Amount for ${formatDate(selectedDate)}`}
              {step === 3 && "Confirm Bid"}
            </DialogTitle>
            <motion.div
              key="loading-indicator"
              variants={container}
              initial='hidden'
              animate='visible'
              className=' flex flex-col justify-center items-center gap-4'>
              {loading ? (
                <div className=''>
                  <div className='absolute top-0 left-0 w-[100vw] h-[100vh] z-[190] backdrop-blur-md'></div>
                  <div className='relative z-[191] mx-auto w-full h-full flex flex-col justify-center items-center'>
                    <Loader2 className='text-muted-foreground mx-auto w-full h-24 animate-spin' />
                  </div>
                </div>
              ) : null}
            </motion.div>
            <PlaceBidForm
              key={dialogKey}
              userLastBid={userLastBid}
              dateInputValue={dateInputValue}
              onDateChange={(date) => {
                setSelectedDate(date);
                handleDateChange(date);
              }}
              datesTaken={datesTaken || []}
              bidInputValue={bidInputValue}
              onBidAmountChange={handleBidAmountChange}
              onIncrement={handleIncrement}
              onDecrement={handleDecrement}
              feeRate={fee_rate}
              onFeeRateChange={setFeeRate}
              getMaxBidForDate={getMaxBidForDate}
              minimumBid={minimumBid}
              isFormValid={isFormValid}
              onSubmit={handleSubmit}
              top16Amounts={bidDates ? bidDates.slice(0, 16).map(bd => bd.top_bid.amount) : []}
              floor3={floor3}
              floor10={floor10}
              floor16={floor16}
              step={step}
              setStep={setStep}
            />
            <DialogDescription className='mt-4 text-sm sm:text-base lg:max-w-[80%] text-wrap'>
            </DialogDescription>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AuctionTableWithBidForm;

const container = {
  hidden: {
    opacity: 0,
    transition: { staggerChildren: 0.5 }
  },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.25, delayChildren: 0.25 }
  }
}
