import { DateInput } from '@/components/ui/DateInput';

interface DateSelectorProps {
  dateInputValue: string;
  onDateChange: (date: string) => void;
  maxDate: string;
  datesTaken: string[];
}

const DateSelector: React.FC<DateSelectorProps> = ({ dateInputValue, onDateChange, maxDate, datesTaken }) => {
  return (
    <div className="space-y-2 p-0">
      <label htmlFor="date" className="block text-sm font-medium">
        Select Date
      </label>
      <div onClick={(e) => e.stopPropagation()}>
        <DateInput
          value={dateInputValue}
          onDateChange={onDateChange}
          minDate="2009-01-09"
          maxDate={maxDate}
          disabledDates={datesTaken}
          className="w-full"
        />
      </div>
    </div>
  )
};

export default DateSelector; 