import { AuctionCard } from '@/components/BidCard/AuctionCard';
import type { KitDateBidSummary } from "@/schemas/auction";
import { motion, Variants } from "framer-motion";
import { useCallback } from "react";

type Nullish<T> = T | null | undefined;

interface AuctionGridProps {
    data: Nullish<KitDateBidSummary[]>;
    onQuickBid?: (date: string, amount: number) => void;
    calculateMinimumBid: (date: string) => number;
}

export function AuctionGrid({ data, onQuickBid, calculateMinimumBid }: AuctionGridProps) {

    const handleClick = useCallback((date: string) => {
        if (onQuickBid) { onQuickBid(date, (calculateMinimumBid(date) + 50000) / 100000000) }
    }, [onQuickBid, calculateMinimumBid]);

    if (!data) {
        return null
    }

    return (
        <>
            {data && data.map((bidDate) => {
                if (!bidDate) { return null }
                return (
                    <motion.div
                        key={`card-${bidDate.top_bid.kit_date}`}
                        variants={item}
                        layout
                        className='order-none col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 mx-auto text-sm font-semibold text-primary'
                    >
                        <AuctionCard
                            key={`AuctionCard-${bidDate.top_bid.kit_date}`}
                            bidDate={bidDate}
                            onClick={() => handleClick(bidDate.top_bid.kit_date)}
                        />
                    </motion.div>
                )
            }
            )}
        </>
    );
}

const item: Variants = {
    hidden: {
        scale: 1,
        opacity: 0,
        y: 8,
        transition: { duration: 0.14 }
    },
    show: {
        scale: [1, 1, 1, 1, 1, 1.08, 1, 1.08, 1, 1, 1, 1, 1, 1],
        opacity: 1,
        y: 0,
        transition: {
            duration: 1,
            y: { duration: 0.14 },
            opacity: { duration: .7, delay: 0.5 },
            scale: { duration: 1.5, delay: 0.5 }

        }
    }
}