import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { cn } from "@/lib/cn";
import { utcDate } from '@/lib/formatters';

interface DateInputProps {
  value: string;
  onDateChange: (date: string) => void;
  minDate: string;
  maxDate: string;
  disabledDates?: string[];
  yearPlaceholder?: string;
  monthPlaceholder?: string;
  dayPlaceholder?: string;
  className?: string;
}

const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>(
  ({
    value,
    onDateChange,
    minDate,
    maxDate,
    disabledDates = [],
    yearPlaceholder = "Year",
    monthPlaceholder = "Month",
    dayPlaceholder = "Day",
    className,
    ...props
  }, ref) => {
    const [parsedYear, parsedMonth, parsedDay] = value.split('-');

    const [year, setYear] = useState(parsedYear);
    const [month, setMonth] = useState(parsedMonth);
    const [day, setDay] = useState(parsedDay);

    const { minYear, maxYear, minMonth, maxMonth } = useMemo(() => ({
      minYear: parseInt(minDate.split('-')[0], 10),
      maxYear: parseInt(maxDate.split('-')[0], 10),
      minMonth: parseInt(minDate.split('-')[1], 10),
      maxMonth: parseInt(maxDate.split('-')[1], 10),
    }), [minDate, maxDate]);

    const years = useMemo(() => Array.from({ length: maxYear - minYear + 1 }, (_, i) => (minYear + i).toString()), [minYear, maxYear]);

    const months = useMemo(() => Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0')), []);

    const getDaysInMonth = (year: number, month: number) => {
      const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      const monthIndex = month - 1;

      if (monthIndex === 1) {
        const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        return isLeapYear ? 29 : 28;
      }

      return daysInMonth[monthIndex];
    };

    const days = useMemo(() => {
      if (year && month) {
        const numDays = getDaysInMonth(parseInt(year), parseInt(month));
        return Array.from({ length: numDays }, (_, i) => (i + 1).toString().padStart(2, '0'));
      }
      return [];
    }, [year, month]);

    const isDateDisabled = useCallback((y: number, m: number, d: number) => {
      const dateString = `${y}-${m.toString().padStart(2, '0')}-${d.toString().padStart(2, '0')}`;
      const date = utcDate(dateString);
      if (!disabledDates) return date < utcDate(minDate) || date > utcDate(maxDate);
      return disabledDates.includes(dateString) || date < utcDate(minDate) || date > utcDate(maxDate);
    }, [minDate, maxDate, disabledDates]);

    const handleYearChange = useCallback((y: string) => {
      setYear(y);
      if (month && day) {
        const newDate = `${y}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        if (isDateDisabled(parseInt(y), parseInt(month), parseInt(day))) {
          setDay('');
          onDateChange(`${y}-${month.padStart(2, '0')}-`);
        } else {
          onDateChange(newDate);
        }
      }
    }, [month, day, onDateChange, isDateDisabled]);

    const handleMonthChange = useCallback((m: string) => {
      setMonth(m);
      if (year && day) {
        const newDate = `${year}-${m.padStart(2, '0')}-${day.padStart(2, '0')}`;
        if (isDateDisabled(parseInt(year), parseInt(m), parseInt(day))) {
          setDay('');
          onDateChange(`${year}-${m.padStart(2, '0')}-`);
        } else {
          onDateChange(newDate);
        }
      }
    }, [year, day, onDateChange, isDateDisabled]);

    const handleDayChange = useCallback((d: string) => {
      setDay(d);
      if (year && month) {
        onDateChange(`${year}-${month.padStart(2, '0')}-${d.padStart(2, '0')}`);
      }
    }, [year, month, onDateChange]);

    const updateDateFromComponents = useCallback(() => {
      if (!year || !month || !day) return;
      const formattedDay = parseInt(day).toString().padStart(2, '0');
      const formattedMonth = parseInt(month).toString().padStart(2, '0');
      const newDate = `${year}-${formattedMonth}-${formattedDay}`;
      if (!isDateDisabled(parseInt(year), parseInt(month), parseInt(day))) {
        onDateChange(newDate);
      }
    }, [year, month, day, isDateDisabled, onDateChange]);

    useEffect(() => {
      updateDateFromComponents();
    }, [updateDateFromComponents]);

    useEffect(() => {
      const [initialYear, initialMonth, initialDay] = value.split('-');
      setYear(initialYear);
      setMonth(initialMonth);
      setDay(initialDay);
    }, [value]);

    const handleRandomDate = useCallback(() => {
      const getRandomDate = (): string => {
        const start = new Date(minDate);
        const end = new Date(maxDate);
        const randomDate = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
        const randomDateString = randomDate.toISOString().slice(0, 10);
        if (disabledDates.includes(randomDateString)) {
          return getRandomDate();
        }
        return randomDateString;
      };

      const randomDate = getRandomDate();
      const [newYear, newMonth, newDay] = randomDate.split('-');
      setYear(newYear);
      setMonth(newMonth);
      setDay(newDay);
      onDateChange(randomDate);
    }, [minDate, maxDate, disabledDates, onDateChange]);

    return (
      <div className={cn("space-y-2", className)}>
        <div className="grid gap-x-4 grid-cols-7 w-full min-w-20">
          <Input
            name="date"
            className='text-xl'
            type="hidden"
            ref={ref}
            value={value}
            onChange={() => { }}
            {...props}
          />
          <Select value={year} onValueChange={handleYearChange}>
            <SelectTrigger className="col-span-2 w-full">
              <SelectValue placeholder={yearPlaceholder} className='text-xl' />
            </SelectTrigger>
            <SelectContent>
              {years.map(y => (
                <SelectItem className='text-xl' key={y} value={y}>{y}</SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Select value={month} onValueChange={handleMonthChange}>
            <SelectTrigger className="col-span-3 w-full min-w-20">
              <SelectValue placeholder={monthPlaceholder} className='text-xl' />
            </SelectTrigger>
            <SelectContent>
              {months.map(m => (
                <SelectItem
                  className='text-xl'
                  key={m}
                  value={m}
                  disabled={year ? (
                    (parseInt(year) === minYear && parseInt(m) < minMonth) ||
                    (parseInt(year) === maxYear && parseInt(m) > maxMonth)
                  ) : false}
                >
                  {new Date(
                    parseInt(year) || new Date().getFullYear(),
                    parseInt(m) - 1,
                    1
                  ).toLocaleString('default', { month: 'long' })}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Select value={day} onValueChange={handleDayChange}>
            <SelectTrigger className="col-span-2 w-full min-w-20">
              <SelectValue placeholder={dayPlaceholder} className='text-xl' />
            </SelectTrigger>
            <SelectContent>
              {days.map(d => (
                <SelectItem
                  className='text-xl'
                  key={d}
                  value={d}
                  disabled={isDateDisabled(parseInt(year), parseInt(month), parseInt(d))}
                >
                  {d}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <Button
          type="button"
          key="randomDateButton"
          variant='outline'
          className="text-sm underline text-accent-foreground w-full"
          onClick={handleRandomDate}
        >
          Random Date
        </Button>
      </div>
    );
  });

DateInput.displayName = "DateInput";

export { DateInput };