import { Button } from '@/components/ui/button';
import { FloatingInput } from '@/components/ui/input';
import { formatBTC } from "@/lib/formatters";
import { Minus, Plus } from 'lucide-react';
import React, { useCallback, useMemo, useState } from 'react';


interface BidInputProps {
  bidInputValue: string | number;
  onBidAmountChange: (amount: string | number) => void;
  onIncrement: () => void;
  onDecrement: () => void;
  minimumBid: number;
  maxForDate: number;
  floor3: number;
  floor10: number;
  top16Amounts: number[];
}

const BidInput: React.FC<BidInputProps> = ({
  bidInputValue,
  onBidAmountChange,
  onIncrement,
  onDecrement,
  minimumBid,
  maxForDate,
  floor3,
  floor10,
  top16Amounts
}) => {

  const valueSats = (typeof bidInputValue === 'string') ? parseFloat(bidInputValue.replace(/,/g, '')) * 1e8 : bidInputValue * 100000000;
  const [nudge, setNudge] = useState<'up' | 'down' | ''>('');
  const [nudgeCount, setNudgeCount] = useState(0);

  const floor = top16Amounts.length >= 16 ? top16Amounts[15] : 0;
  const validForDate = valueSats >= maxForDate + 50000;
  const validForCycle = valueSats >= floor + 50000;

  const yourPlace = useMemo(() => {
  
    let place_ = top16Amounts.findIndex((amount) => amount < valueSats);
    if (place_ === -1 && top16Amounts.length < 16) {
      place_ = top16Amounts.length + 1;
    }
    const place = formatRankNumber(place_);
    return place
  }, [valueSats, top16Amounts]);

  const handleNudge = useCallback((direction: 'up' | 'down') => {
    direction === 'up' ? onIncrement() : onDecrement();
    setNudgeCount((prev) => prev + 1);
    setNudge(direction);
    setTimeout(() => setNudge(''), 240);
  }, [onIncrement, onDecrement]);

  const handleSetPlace = useCallback((place: number) => {
    if (top16Amounts.length < place) { return 0.00010000 }
    const newBidAmountSats = (top16Amounts[place - 1] + 50000);
    const newBidAmountBTC = (newBidAmountSats / 100000000);
    const direction = newBidAmountSats >= valueSats ? 'up' : 'down';
    if (newBidAmountSats < minimumBid) { return }
    onBidAmountChange(newBidAmountBTC);
    setNudgeCount((prev) => prev + 1);
    setNudge(direction);
    setTimeout(() => setNudge(''), 240);
  }, [onBidAmountChange, minimumBid, top16Amounts, valueSats]);

  return (
    <div className="flex flex-col gap-4">
      <div>
        <label htmlFor="bidAmount" className="block text-sm font-medium">
          Your Bid (BTC)
        </label>
        <div className='grid grid-cols-5 gap-4'>
          <div className="relative col-span-2">
            <FloatingInput
              nudge={nudge}
              nudgeCount={nudgeCount}
              id="bidAmount"
              name="bidAmount"
              type="text"
              value={bidInputValue}
              placeholder="Enter bid (BTC)"
              onChange={(e) => onBidAmountChange(e.target.value)}
              textSize="xl"
              invalid={(valueSats * 100000000) < minimumBid + 50000}
              className="w-full pr-24"
            />
          </div>
          <div className='ml-2 text-sm col-span-3'>
            <span className="text-sm">
              Must be 0.0005 BTC higher than:
            </span>
            <ul className='pl-4 text-muted-foreground list-disc'>
              {maxForDate > 0 && <li className=''>
                <span className={validForDate ? '' : 'text-destructive'}>This date's top bid: {formatBTC(maxForDate.toLocaleString())}</span>
              </li>}
              <li className=''>
                <span className={validForCycle ? '' : 'text-destructive'}>The 16th place bid: {formatBTC(floor.toLocaleString())} </span>
              </li>
            </ul>
          </div>
          <span className="col-span-2 row-start-2  text-xs text-muted-foreground">
            Quick bid:
          </span>
          <Button
            type="button"
            onMouseDown={() => handleNudge('down')}
            className="border-primary/80 row-start-3 py-2 h-12 text-sm flex items-center justify-center gap-2"
            variant="outline"
            disabled={((typeof bidInputValue === 'string') ? parseFloat(bidInputValue.replace(/,/g, '')) : bidInputValue * 100000000) <= 0}
          >

            <Minus size={24} />
          </Button>
          <Button
            type={'button'}
            onMouseDown={() => handleSetPlace(10)}
            className="text-lg row-start-3 col-span-1 border-primary/80 w-full py-6 flex items-center justify-center gap-2"
            variant="outline"
            disabled={floor10 < minimumBid}
          >
            <span>
              Top 10
            </span>
          </Button>
          <Button
            type={'button'}
            onMouseDown={() => handleSetPlace(3)}
            className="row-start-3 col-span-1 border-primary/80 w-full py-6 text-lg flex items-center justify-center gap-2"
            variant="outline"
            disabled={floor3 < minimumBid}
          >
            <span>
              Top 3
            </span>
          </Button>
          <Button
            type={'button'}
            onMouseDown={() => handleSetPlace(1)}
            className="row-start-3 col-span-1 border-primary/80 w-full py-6 text-lg flex items-center justify-center gap-2"
            variant="outline"
          >
            <span>
              1<sup>st</sup>
            </span>
          </Button>
          <Button
            type="button"
            onMouseDown={() => handleNudge('up')}
            className="border-primary/80 row-start-3 py-2 h-12 text-sm flex items-center justify-center gap-2"
            variant="outline"
          >
            <Plus size={24} />
          </Button>
        </div>
        <div className='mt-4 font-bold text-sm'>
          Your bid will be in {yourPlace} place!
        </div>
      </div>
    </div>
  );
};

export default BidInput;


const formatRankNumber = (rank: number) => {
  if (rank === 1) {
    return '1st'
  } else if (rank === 2) {
    return '2nd'
  } else if (rank === 3) {
    return '3rd'
  } else {
    return `${rank}th`
  }
};